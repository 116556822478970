import { React } from "react";

const FooterSection = () => {
    return (
        <footer className="footer_copy">
            <div className="container">
                <p>Copyright @ All right reserved. 2023</p>
            </div>
        </footer>
    )
}

export default FooterSection